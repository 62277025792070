<template> 
    <div class="page-content header-clear-small">

        <div class="card card-style" v-if="!loading">
            <div class="content">
                <h4 class="mb-0">{{report.entity.name}}</h4>
                <p>
                    {{report.description}}
                </p>
            </div>
            <div class="divider mb-0"></div>
				<div class="content mb-4">
                <div class="list-group list-custom-small">
                    <a href="#">
                        <i class="fa font-14 fa-cog color-brown1-dark"></i>
                        <span>{{report.category.name}}</span>
                        
                    </a> 
                    <a href="#" v-if="report.followed == true">
                        <i class="fa font-14 fa-cog color-brown1-dark"></i>
                        <span>Statut : {{report.life.name}}</span>
                        
                    </a>
                    <a href="#">
                        <i class="fa font-14 fa-cog color-brown1-dark"></i>
                        <span>Latitude: {{report.latitude}}</span>
                       
                    </a> 
				<a href="#">
                        <i class="fa font-14 fa-cog color-brown1-dark"></i>
                        <span>Longitude: {{report.longitude}}</span>
                       
                    </a> 					
            
                </div>
            </div>	
			<div class="divider mb-0"></div>
			<div class="ml-2 mr-2">
			<div class='responsive-iframe max-iframe'><iframe :src="'https://maps.google.com/?ie=UTF8&ll='+report.latitude+','+report.longitude+'&spn=0.006186,0.016512&t=h&z=16&output=embed'" frameborder="0" allowfullscreen></iframe></div>
			</div>
			<div class="divider mb-0"></div>
            <div class="gallery-view-controls" v-if="report.media && report.media.downloads.length > 0">
                <a href="#" class="gallery-view-1-activate" :class="displayModeColor1" @click.prevent="displayMode(1)"><i class="fa fa-th"></i></a>
                <a href="#"  @click.prevent="displayMode(2)" class="gallery-view-2-activate"  :class="displayModeColor2"><i class="fa fa-th-large"></i></a>
                <a href="#"  @click.prevent="displayMode(3)" class="gallery-view-3-activate"  :class="displayModeColor3"><i class="fa fa-bars"></i></a>
                <div class="clearfix"></div>
            </div>
			
            <div class="content my-n1" v-if="report.media && report.media.downloads.length > 0">
                <div class="gallery-views" :class="displayModeClass">
                    <a v-for="picture in report.media.downloads"  v-bind:key="picture.id" data-lightbox="gallery-1" :href="picture.download_url" :title="report.category.name+ ' - ' + report.entity.name">
						<img v-if="!isVideo(picture.type)" :src="picture.download_url" :data-src="picture.download_url" class="rounded-m preload-img shadow-l img-fluid" :alt="picture.id">
						<div v-else style="width:100%">
							<video width="100%" height="auto" controls>
							<source :src="picture.download_url" :type="picture.type">
							Your browser does not support the video tag.
							</video>					
                       </div>						
                        <div class="caption">
                            <h4 class="color-theme">{{report.entity.name}}</h4>
                            <p>{{report.category.name}}</p>
                            <div class="divider bottom-0"></div>
                        </div>
                    </a>
                </div>
            </div>			
        </div>
        <div class="card card-style" v-if="!loading">
            <div class="content" v-if="report.messages && (report.messages.length > 0)">
			<h4 class="text-center mt-0">Réponses ({{report.messages.length}})</h4>
			<div class="divider"></div>
				<div v-for="message in report.messages" v-bind:key="message.id">
                <!--<h4 class="mb-2n1 font-14" v-if="message.nickname"></h4>-->
                <h5 class="float-right font-11 fa-3x mt-n3 color-gray">{{ message.created_at | moment('DD/MM/YYYY hh:mm:ss')}}</h5>
                <p class="mb-2" v-if="message.nickname"><i class="fa fa-check-circle color-green1-dark scale-icon mr-2"></i>{{message.nickname}}</p>
                <p class="font-16">
                    {{message.description}}
					<span class="d-block" v-if="message.link"><a  class="btn btn-s rounded-sm bg-green-dark-sdw color-white mt-3 text-uppercase font-800 text-uppercase" :href="message.link" target="_blank"><i class="fas fa-link"></i></a></span>
									
					
					
					<span class="d-block text-right" v-if="message.status == 1">
						<a href="#" @click.prevent="setEditedId(message.id, message.from_id)"><i class="fa fa-arrow-right bg-red-dark-sdw rounded-m color-white" style="font-size: 12px; padding: 6px;"></i><strong class="ml-2">Répondre</strong></a>
					</span>
				</p>
				<fieldset v-if="editedId == message.id">
                        <div class="form-field form-text">					
							<textarea name="contactMessageTextarea" v-model="response" style="height:80px" class="contactTextarea round-small requiredField" :class="messageError ? 'border-red-dark':''" placeholder="Votre réponse"></textarea>
                        </div>

                        <div class="form-button">
							<a href="#" @click.prevent="cancel" class="btn bg-gray-dark-sdw text-uppercase font-900 btn-m rounded-sm  shadow-xl text-white float-left mb-4">Annuler</a>
							
							<a href="#" @click.prevent="sendResponse" class="btn bg-orange-dark-sdw text-uppercase font-900 btn-m rounded-sm  shadow-xl float-left ml-4 mb-4 text-white">Envoyer</a>
                        </div>			
                </fieldset>				
				<div class="divider"></div>
				</div>
            </div>
        </div>
    </div>
    <!-- End of Page Content--> 
</template>

<script>

import AppConstants from '@/config'
export default {
  name: 'Signalement',
  data() {
    return{
		report : {},
		loading: true,
		mapCode:'',
		displayModeClass:'gallery-view-3',
		displayModeColor1:'',
		displayModeColor2:'',
		displayModeColor3:'color-highlight',
		editedId: 0,
		editedTo: 0,
		response: '',
		messageError: false
	}
  },
  props: {
    id: {
      type: [String, Number],
      default: 0
    },
    isloggedin: {
      type: Number,
      default: 5
    }	
  }, 
  components: {
  },
  created() {
     this.getReport()
  },  
  mounted() {

  }, 
	unmounted() {
     if (this.report == {}) this.getReport()
  },  
  methods: {  
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},  
  displayMode(nb) {
	if (nb == 1) {
		this.displayModeClass='gallery-view-1';
		this.displayModeColor1='color-highlight';
		this.displayModeColor2='';
		this.displayModeColor3='';
	}
	if (nb == 2) {
		this.displayModeClass='gallery-view-2';
		this.displayModeColor1='';
		this.displayModeColor2='color-highlight';
		this.displayModeColor3='';		
	}
	if (nb == 3) {
		this.displayModeClass='gallery-view-3';
		this.displayModeColor1='';
		this.displayModeColor2='';
		this.displayModeColor3='color-highlight';	
	}
  },	
  isVideo(type) {
		if ( (type == 'application/vnd.apple.mpegurl') ||  	//.m3u, .m3u8
		(type == 'application/x-mpegurl') || 	//.m3u, .m3u8
		(type == 'video/3gpp') || 	//.3gp
		(type == 'video/mp4') || 	//.mp4, .m4a, .m4p, .m4b, .m4r, .m4v
		(type == 'video/mpeg') || 	//.m1v
		(type == 'video/ogg') || 	//.ogg
		(type == 'video/quicktime') || 	//.mov, .qt
		(type == 'video/webm') || 	//.webm
		(type == 'video/x-m4v') || 	//.m4v
		(type == 'video/ms-asf') || 	//.asf, .wma, .wmv
		(type == 'video/x-ms-wmv') || 	//.wmv
		(type == 'video/x-msvideo') )
		return true;
		return false;

  },  
  getReport() {
			this.loading = true
				var token = this.getConst('API_TOKEN')
				var id = this.getConst('API_ID')
				var apiUrl = this.getConst('API_URL')+"user-report"				
				var api_token = localStorage.getItem('token')
                if ((this.id != 0) && (api_token)) {
                    var apiData = { 
					"report": this.id,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
					if (response.data.message == 'OK') {
						this.report = response.data.data
						this.loading = false
					}
				})
				.catch(error => {
				console.log(error.message);
				})	
				} 
  
  
  },
	setEditedId(id, to) {
		this.editedId = id
		this.editedTo = to
	},
	sendResponse(){
				this.messageError = false
				if ((!this.response) || (this.response == '')) {
					this.messageError = true
					return false
				}
				if (!this.editedId) {
					this.messageError = true
					return false
				}
				var token = this.getConst('API_TOKEN')
				var id = this.getConst('API_ID')
				var apiUrl = this.getConst('API_URL')+"user-response"				
				var api_token = localStorage.getItem('token')
                if (api_token && id) {
                    var apiData = { 
					"to": this.editedTo,
					"report": this.report.id,
					"message": this.editedId,
					"response": this.response,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
					if (response.data.message == 'OK') {
						this.messageError = false
						this.editedId = 0
						this.editedTo = 0
						this.report = response.data.data
					}
					})
				} 	
	
	
	},
	cancel(){
		this.editedId = 0
		this.editedTo = 0
	},  
  }
}
</script>
<style>
.border-red-dark {
    border-color: #DA4453 !important;
}
.mr-2 {margin-right:20px; }
</style>
